import store from '@/store/index';
import { WishlistServiceReadyEvent } from '@studyportals/student-interfaces';
import { IEventAggregationService, ISubscriber } from '@studyportals/event-aggregation-service-interface';
import globals from '@/utils/globals';

export class DependencyCheckerPlugin implements ISubscriber<WishlistServiceReadyEvent> {
	public initialize(): void {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const eventAggregationService: IEventAggregationService = window['EventAggregationService'];
		if (!eventAggregationService) {
			return;
		}

		globals.eventAggregationService = eventAggregationService;
		eventAggregationService.subscribeTo(WishlistServiceReadyEvent.EventType, this, true);
	}

	public async notify(event: WishlistServiceReadyEvent): Promise<void> {
		await this.handleWishlistServiceReady(event);
	}

	private async handleWishlistServiceReady(event: WishlistServiceReadyEvent): Promise<void> {
		globals.wishlistService = event.wishlistService;
		await store.actions.setAreDependenciesLoaded(true);
	}
}
