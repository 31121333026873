<template>
	<div class="Favouriting" v-if="isReady">
		<IconOnly
			v-if="button_type === 'icon_only'"
			:study_id="study_id"
			:button_type="button_type"
			:button_location="button_location"
		/>
		<Floating
			v-if="button_type === 'floating_button'"
			:study_id="study_id"
			:button_type="button_type"
			:button_location="button_location"
		/>
		<StudyHeader
			v-if="button_type === 'study_header'"
			:study_id="study_id"
			:button_type="button_type"
			:button_location="button_location"
			:button_class="button_class"
		/>

		<BestFit
			v-if="button_type === 'best_fit'"
			:study_id="study_id"
			:button_type="button_type"
			:button_location="button_location"
		/>
	</div>
</template>

<script lang="ts" src="./app.ts"></script>
