import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "Favouriting"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconOnly = _resolveComponent("IconOnly")!
  const _component_Floating = _resolveComponent("Floating")!
  const _component_StudyHeader = _resolveComponent("StudyHeader")!
  const _component_BestFit = _resolveComponent("BestFit")!

  return (_ctx.isReady)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.button_type === 'icon_only')
          ? (_openBlock(), _createBlock(_component_IconOnly, {
              key: 0,
              study_id: _ctx.study_id,
              button_type: _ctx.button_type,
              button_location: _ctx.button_location
            }, null, 8 /* PROPS */, ["study_id", "button_type", "button_location"]))
          : _createCommentVNode("v-if", true),
        (_ctx.button_type === 'floating_button')
          ? (_openBlock(), _createBlock(_component_Floating, {
              key: 1,
              study_id: _ctx.study_id,
              button_type: _ctx.button_type,
              button_location: _ctx.button_location
            }, null, 8 /* PROPS */, ["study_id", "button_type", "button_location"]))
          : _createCommentVNode("v-if", true),
        (_ctx.button_type === 'study_header')
          ? (_openBlock(), _createBlock(_component_StudyHeader, {
              key: 2,
              study_id: _ctx.study_id,
              button_type: _ctx.button_type,
              button_location: _ctx.button_location,
              button_class: _ctx.button_class
            }, null, 8 /* PROPS */, ["study_id", "button_type", "button_location", "button_class"]))
          : _createCommentVNode("v-if", true),
        (_ctx.button_type === 'best_fit')
          ? (_openBlock(), _createBlock(_component_BestFit, {
              key: 3,
              study_id: _ctx.study_id,
              button_type: _ctx.button_type,
              button_location: _ctx.button_location
            }, null, 8 /* PROPS */, ["study_id", "button_type", "button_location"]))
          : _createCommentVNode("v-if", true)
      ]))
    : _createCommentVNode("v-if", true)
}