import { IState, IStore } from '@/interfaces/store.interfaces';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import state from './state';
import { readonly } from 'vue';

const store: IStore = {
	state: readonly(state) as IState,
	mutations,
	getters,
	actions,
};

export default store;
