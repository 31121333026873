import Button from '../Button.class';
import store from '@/store';
import { computed } from 'vue';

export default class IconOnly extends Button {
	public openIncentive = computed((): boolean => {
		return store.getters.bnwModeActive();
	});

	protected shouldBeAnimated(): boolean {
		if (!this.root.value) {
			return false;
		}

		return !this.wishlisted.value && this.canAnimate.value;
	}

	public mounted(): void {
		if (
			this.button_location === 'study_fixed_essential_information' ||
			this.button_location === 'study_essential_information'
		) {
			this.initialAnimationTimeout = 500;
			this.animationInterval = 10000;
			this.canAnimate.value = true;
			this.animateButton();

			document.addEventListener('bestfit_fit_saved', () => {
				this.animateButton();
			});
		}

		document.addEventListener('wishlist_bnw_mode', () => {
			store.mutations.triggerBNWMode();
		});

		document.dispatchEvent(new Event('wishlist_bnw_ready'));
	}
}
