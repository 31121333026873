import { IMutations } from '@/interfaces/store.interfaces';
import state from './state';
import { IWishlist } from '@/interfaces/wishlist.interface';

const mutations: IMutations = {
	setAreDependenciesLoaded: (areDependenciesLoaded: boolean): void => {
		state.areDependenciesLoaded = areDependenciesLoaded;
	},
	setIsReady: (isReady: boolean) => {
		state.isReady = isReady;
	},
	handleErrorState: () => {
		state.error = 'sync_error';
	},
	triggerBNWMode: () => {
		state.bnwModeActive = true;
	},
	setLoggedInState: (loggedInState) => {
		state.loggedIn = loggedInState;
	},
	addFavourite: (id) => {
		const wishlistCopy = [...state.wishlist];
		wishlistCopy.push({
			study: {
				id,
			},
		});
		state.wishlist = wishlistCopy;
	},
	removeFavourite: (id) => {
		const wishlistCopy = [...state.wishlist];
		state.wishlist = wishlistCopy.filter((favourite) => favourite.study.id !== id);
	},
	updateWishlistValue: (wishlist: IWishlist[]) => {
		state.wishlist = wishlist;
	},
};

export default mutations;
