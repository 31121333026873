import { App } from 'vue';

declare const STAGE: string;

export default {
	install: (app: App<Element>): void => {
		const rollbarConfig = {
			accessToken: 'baa6a6f53d5847bf8d3fe0a8a901bc57',
			captureUncaught: false,
			captureUnhandledRejections: false,
			payload: {
				environment: STAGE,
			},
		};

		const rollbar = window && window['rollbar'] ? new window['rollbar'](rollbarConfig) : null;

		app.config.errorHandler = (error: any): void => {
			if (rollbar === null) {
				return;
			}

			if (STAGE === 'prd') {
				rollbar.error(error);
			}

			throw error;
		};
	},
};
