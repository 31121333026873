import { defineComponent, onMounted } from 'vue';
import IconOnly from '@/presentation/components/Buttons/IconOnly/IconOnly.class';

export default defineComponent({
	props: {
		study_id: String,
		button_type: String,
		button_location: String,
	},
	setup: (props) => {
		const component = new IconOnly(
			props.study_id as string,
			props.button_type as string,
			props.button_location as string
		);
		component.created();
		onMounted(component.mounted.bind(component));

		return {
			openIncentive: component.openIncentive,
			wishlisted: component.wishlisted,
			favouriteStudy: component.favouriteStudy.bind(component),
			isAnimated: component.isAnimated,
			root: component.root,
		};
	},
});
