<template>
	<button @click="favouriteStudy(true)" :class="[buttonClass, { 'is-animated': isAnimated }]" class="StudyHeaderButton">
		{{ wishlisted ? 'Added to wishlist' : 'Add to wishlist' }}

		<i class="HeartIcon" :class="wishlisted ? 'lnr-heart-full' : 'lnr-heart'"> </i>
	</button>
</template>

<script lang="ts" src="./StudyHeader.ts"></script>
<style lang="scss" scoped src="./StudyHeader.scss"></style>
