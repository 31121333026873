import { IActions } from '@/interfaces/store.interfaces';
import mutations from './mutations';
import getters from './getters';
import { WishlistSyncPlugin } from '@/store/plugins/wishlist-sync-plugin';
import { HandleLoggedInState } from '@/store/plugins/handle-loggedin-state';
import store from '@/store/index';
import { Favourite } from '@studyportals/wishlist-service-core';
import globals from '@/utils/globals';

const actions: IActions = {
	setAreDependenciesLoaded: async (areDependenciesLoaded: boolean) => {
		store.mutations.setAreDependenciesLoaded(areDependenciesLoaded);

		if (areDependenciesLoaded) {
			void new WishlistSyncPlugin().initiate();
			void new HandleLoggedInState().initiate();
		}
	},
	addFavourite: async (id: number) => {
		if (getters.wishlistLimitReached()) {
			actions.fireWishlistLimitReachedEvent();
			return;
		}

		mutations.addFavourite(id);
		await globals.wishlistService.addFavourite(id);
	},
	removeFavourite: async (id: number) => {
		mutations.removeFavourite(id);
		await globals.wishlistService.removeFavourite(id);
	},
	updateWishlist: (favourites: Favourite[]): void => {
		store.mutations.updateWishlistValue(favourites);
		actions.fireWishlistSyncedEvent();
	},
	fireWishlistSyncedEvent: () => {
		document.dispatchEvent(new Event('wishlist_synced'));
	},
	fireWishlistLimitReachedEvent: () => {
		document.dispatchEvent(
			new CustomEvent('Notification', {
				detail: {
					key: 'WishlistLimitReached',
				},
			})
		);
	},
	setLoggedInState: (loggedInState: boolean) => {
		mutations.setLoggedInState(loggedInState);
	},
};

export default actions;
