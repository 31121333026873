import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.favouriteStudy(true))),
    class: "ChampionButton BestFitButton"
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(["HeartIcon", _ctx.wishlisted ? 'lnr-heart-full' : 'lnr-heart'])
    }, null, 2 /* CLASS */),
    _createTextVNode(" " + _toDisplayString(_ctx.wishlisted ? 'Added to wishlist' : 'Add to wishlist'), 1 /* TEXT */)
  ]))
}