import { IGetters } from '@/interfaces/store.interfaces';
import state from './state';
import store from '@/store/index';

const getters: IGetters = {
	isReady: () => store.state.isReady,
	wishlistLimitReached: (): boolean => state.wishlist.length >= 99,
	bnwModeActive: (): boolean => state.bnwModeActive,
	isLoggedIn: (): boolean => state.loggedIn,
};

export default getters;
