import Button from '../Button.class';
import { ref } from 'vue';
export default class Floating extends Button {
	public offCanvas = ref(true);
	public threshold = ref(300);

	mounted(): void {
		this.animateButton();
		this.calculateThreshold();

		window.addEventListener('scroll', () => {
			this.showOrHide();
		});
		window.addEventListener('resize', () => {
			this.calculateThreshold();
		});
	}

	showOrHide(): void {
		if (window.pageYOffset > this.threshold.value) {
			this.offCanvas.value = false;
		} else {
			if (window.pageYOffset < this.threshold.value) {
				this.offCanvas.value = true;
			}
		}
	}

	calculateThreshold(): void {
		// The header on the studypage
		const studyHeader = document.getElementById('HeroContainer');

		if (studyHeader) {
			// Set the threshold to the position of the header wishlist button + the height of it
			this.threshold.value = studyHeader.clientHeight;
		}
	}
}
