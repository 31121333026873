import store from '@/store';
import { FavouriteRemoved, FavouriteAdded } from '@studyportals/wishlist-service-core';
import globals from '@/utils/globals';

export class WishlistSyncPlugin {
	public async notify(): Promise<void> {
		await this.updateWishlist();
	}

	public async initiate(): Promise<void> {
		globals.eventAggregationService.subscribeTo(FavouriteAdded.EventType, this);
		globals.eventAggregationService.subscribeTo(FavouriteRemoved.EventType, this);

		await this.updateWishlist();
		store.mutations.setIsReady(true);
	}

	private async updateWishlist(): Promise<void> {
		const wishlist = await globals.wishlistService.getWishlist();

		if (wishlist === null) {
			return;
		}

		const shallowCopyFavourites = wishlist.favourites.slice(0);
		store.actions.updateWishlist(shallowCopyFavourites);
	}
}
