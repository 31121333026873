<template>
	<button
		ref="root"
		@click="favouriteStudy(openIncentive, $event)"
		class="IconOnlyButton"
		:class="{ 'is-animated': isAnimated }"
		:aria-label="wishlisted ? 'Remove from wishlist' : 'Add to wishlist'"
	>
		<i class="HeartIcon" :class="wishlisted ? 'lnr-heart-full' : 'lnr-heart'"> </i>
	</button>
</template>

<script lang="ts" src="./IconOnly.ts"></script>
<style lang="scss" scoped src="./IconOnly.scss"></style>
