import { IState } from '@/interfaces/store.interfaces';
import { reactive } from 'vue';

const state: IState = reactive({
	areDependenciesLoaded: false,
	isReady: false,
	wishlist: [],
	error: null,
	loggedIn: false,
	bnwModeActive: false,
});

export default state;
