import WishlistTracking from './tracking';
import { IWishlistService } from '@studyportals/student-interfaces';
import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';

const wishlistTracking = new WishlistTracking();
const wishlistService: IWishlistService = {} as IWishlistService;
const eventAggregationService: IEventAggregationService = {} as IEventAggregationService;

export default {
	eventAggregationService,
	wishlistTracking,
	wishlistService,
};
