<template>
	<button
		@click="favouriteStudy(true)"
		:class="{ OffCanvas: offCanvas, 'is-animated': isAnimated }"
		type="button"
		class="FloatingWishlistButton"
		:aria-label="wishlisted ? 'Remove from wishlist' : 'Add to wishlist'"
	>
		<i class="HeartIcon" :class="wishlisted ? 'lnr-heart-full' : 'lnr-heart'"> </i>
		<span class="ButtonText">
			{{ wishlisted ? 'Added to wishlist' : 'Add to wishlist' }}
		</span>
	</button>
</template>

<script lang="ts" src="./Floating.ts"></script>
<style lang="scss" scoped src="./Floating.scss"></style>
