import { defineComponent, onMounted } from 'vue';
import StudyHeader from '@/presentation/components/Buttons/StudyHeader/StudyHeader.class';

export default defineComponent({
	props: {
		study_id: String,
		button_type: String,
		button_location: String,
		button_class: String,
	},
	setup: (props) => {
		const component = new StudyHeader(
			props.study_id as string,
			props.button_type as string,
			props.button_location as string,
			props.button_class as string
		);

		component.created();
		onMounted(component.mounted.bind(component));

		return {
			wishlisted: component.wishlisted,
			favouriteStudy: component.favouriteStudy.bind(component),
			isAnimated: component.isAnimated,
			buttonClass: component.buttonClass,
		};
	},
});
