import { defineComponent, onMounted } from 'vue';
import Floating from '@/presentation/components/Buttons/Floating/Floating.class';

export default defineComponent({
	props: {
		study_id: String,
		button_type: String,
		button_location: String,
	},
	setup: (props) => {
		const component = new Floating(
			props.study_id as string,
			props.button_type as string,
			props.button_location as string
		);
		component.created();
		onMounted(component.mounted.bind(component));

		return {
			offCanvas: component.offCanvas,
			threshold: component.threshold,
			wishlisted: component.wishlisted,
			favouriteStudy: component.favouriteStudy.bind(component),
			isAnimated: component.isAnimated,
			showOrHide: component.showOrHide.bind(component),
			calculateThreshold: component.calculateThreshold.bind(component),
		};
	},
});
