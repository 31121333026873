import ITrackingObject from '@/interfaces/trackingobject-interface';
import categories from '@/dictionary/categories';
import actions from '@/dictionary/actions';
import labels from '@/dictionary/labels';

export default class WishlistTracking {
	public trackingDictionary: any;

	constructor() {
		this.trackingDictionary = {
			actions,
			categories,
			labels,
		};
	}

	public snowPlow(trackingParams: ITrackingObject): void | null {
		if (typeof window.snowplow === 'undefined') return null;

		window.snowplow('trackStructEvent', trackingParams);
	}
}
