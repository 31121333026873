import store from '@/store';
import { computed } from 'vue';
import WishlistTracking from '@/utils/tracking';
import { SessionCreatedEvent } from '@studyportals/student-interfaces';
import { ref, Ref } from 'vue';
import { IWishlist } from '@/interfaces/wishlist.interface';

export default class Button {
	public root: Ref<HTMLInputElement | null> = ref(null);
	public initialAnimationTimeout = 45000;
	public animationInterval = 45000;
	public animationTriggered: Ref<boolean> = ref(false);
	public isAnimated: Ref<boolean> = ref(false);
	public canAnimate: Ref<boolean> = ref(false);

	constructor(
		public study_id: string,
		public buttonType: string,
		public button_location: string,
		public buttton_class?: string
	) {}

	public tracking = computed((): WishlistTracking => {
		return new WishlistTracking();
	});

	public studyId = computed((): number => {
		return parseInt(this.study_id);
	});

	public buttonLocation = computed((): string => {
		return String(this.button_location);
	});

	public buttonClass = computed((): string => {
		return String(this.buttton_class);
	});

	public isLoggedIn = computed((): boolean => {
		return store.getters.isLoggedIn();
	});

	public wishlisted = computed((): boolean => {
		const matchingFavourites = store.state.wishlist.filter((favourite: IWishlist) => {
			return favourite.study.id === this.studyId.value;
		});

		return matchingFavourites.length > 0;
	});

	created(): void {
		document.addEventListener('trigger_action_animations', () => {
			this.canAnimate.value = true;
		});
	}

	public favouriteStudy(showIncentive = false, event: Event | null = null): void {
		if (event !== null) {
			event.preventDefault();
		}

		if (this.wishlisted.value) {
			void this.removeFavourite();
			return;
		}
		void this.addFavourite(showIncentive);
	}

	public animateButton(): void {
		if (this.animationTriggered.value) {
			return;
		}

		this.animationTriggered.value = true;

		setTimeout(() => {
			if (this.shouldBeAnimated()) this.toggleAnimation();

			setInterval(() => {
				if (this.shouldBeAnimated()) this.toggleAnimation();
			}, this.animationInterval);
		}, this.initialAnimationTimeout);
	}

	private toggleAnimation(): void {
		this.isAnimated.value = true;

		setTimeout(() => {
			this.isAnimated.value = false;
		}, 2000);
	}

	protected shouldBeAnimated(): boolean {
		if (!this.root.value) {
			return false;
		}

		return this.root.value.classList.contains('OffCanvas') && !this.wishlisted.value && this.canAnimate.value;
	}

	private async addFavourite(showIncentive: boolean): Promise<void> {
		if (showIncentive && this.isLoggedIn.value === false) {
			this.openRegistrationPopup();
		}

		await store.actions.addFavourite(this.studyId.value);

		const action = this.tracking.value.trackingDictionary.actions.FavouriteAdd;
		if (typeof action === 'string') {
			this.trackStudy(action);
		}
		this.broadcastFavouriteAddedInteraction();
	}

	private async removeFavourite(): Promise<void> {
		await store.actions.removeFavourite(this.studyId.value);

		const action = this.tracking.value.trackingDictionary.actions.FavouriteRemove;
		if (typeof action === 'string') {
			this.trackStudy(action);
		}
	}

	private openRegistrationPopup(): void {
		document.dispatchEvent(
			new CustomEvent('RenderAuthenticationService', {
				detail: {
					referrer: 'FavouriteStudy',
					tabToShow: 'third_party_login',
				},
			})
		);

		this.trackIncentiveImpression();
		this.showWishlistAfterRegistration();
	}

	private showWishlistAfterRegistration(): void {
		const eventAggregationService = window.EventAggregationService;

		if (!eventAggregationService) return;

		eventAggregationService.subscribeTo(SessionCreatedEvent.EventType, {
			notify: () => {
				document.dispatchEvent(new Event('openWishlistDrawer'));
			},
		});
	}

	private trackStudy(action: string): void {
		const trackingObject = {
			category: this.tracking.value.trackingDictionary.categories.Wishlisting,
			action,
			label: this.buttonType,
			property: this.buttonLocation.value,
			value: this.studyId.value,
		};

		this.tracking.value.snowPlow(trackingObject);
	}

	private trackIncentiveImpression(): void {
		const trackingObject = {
			category: this.tracking.value.trackingDictionary.categories.Profile,
			action: this.tracking.value.trackingDictionary.actions.RegisterIncentiveClick,
			label: this.tracking.value.trackingDictionary.labels.FavouriteAdd,
		};

		this.tracking.value.snowPlow(trackingObject);
	}

	private broadcastFavouriteAddedInteraction(): void {
		document.dispatchEvent(
			new CustomEvent('favourite_added_interaction', {
				detail: {
					studyId: this.studyId.value,
				},
			})
		);
	}
}
