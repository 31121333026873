import { defineComponent } from 'vue';
import Button from '@/presentation/components/Buttons/Button.class';

export default defineComponent({
	props: {
		study_id: String,
		button_type: String,
		button_location: String,
	},
	setup: (props) => {
		const component = new Button(
			props.study_id as string,
			props.button_type as string,
			props.button_location as string
		);

		component.created();

		return {
			wishlisted: component.wishlisted,
			favouriteStudy: component.favouriteStudy.bind(component),
		};
	},
});
